$(function () {
  var $text = $('.textOverflow-1');//対象のテキスト
  var $more = $('.more');//続きを読むボタン
  var lineNum = 2;//表示する行数
  var textHeight = $text.height();//テキスト全文の高さ
  var lineHeight = parseFloat($text.css('line-height'));//line-height
  var textNewHeight = lineHeight * lineNum;//指定した行数までのテキストの高さ

  // テキストが表示制限の行数を超えたら発動
  if (textHeight > textNewHeight) {
    $text.css({
      height: textNewHeight,
      overflow: 'hidden',
    });
		$('.showMoreDot-1').css("display","block");
    //続きを読むボタンクリックで全文表示
    // $more.click(function () {
    //   $(this).hide();
    //   $text.css({
    //     'height': textHeight,
    //     'overflow': 'visible',
    //   });
    //   return false;//aタグ無効化
    // });
  } else {
    // 指定した行数以下のテキストなら続きを読むは表示しない
    // $more.hide();
  }
});
$(function () {
  var $text = $('.textOverflow-2');//対象のテキスト
  var $more = $('.more');//続きを読むボタン
  var lineNum = 2;//表示する行数
  var textHeight = $text.height();//テキスト全文の高さ
  var lineHeight = parseFloat($text.css('line-height'));//line-height
  var textNewHeight = lineHeight * lineNum;//指定した行数までのテキストの高さ

  // テキストが表示制限の行数を超えたら発動
  if (textHeight > textNewHeight) {
    $text.css({
      height: textNewHeight,
      overflow: 'hidden',
    });
		$('.showMoreDot-2').css("display","block");
    //続きを読むボタンクリックで全文表示
    // $more.click(function () {
    //   $(this).hide();
    //   $text.css({
    //     'height': textHeight,
    //     'overflow': 'visible',
    //   });
    //   return false;//aタグ無効化
    // });
  } else {
    // 指定した行数以下のテキストなら続きを読むは表示しない
    // $more.hide();
  }
});

$(function () {
  var $text = $('.textOverflow-3');//対象のテキスト
  var $more = $('.more');//続きを読むボタン
  var lineNum = 2;//表示する行数
  var textHeight = $text.height();//テキスト全文の高さ
  var lineHeight = parseFloat($text.css('line-height'));//line-height
  var textNewHeight = lineHeight * lineNum;//指定した行数までのテキストの高さ

  // テキストが表示制限の行数を超えたら発動
  if (textHeight > textNewHeight) {
    $text.css({
      height: textNewHeight,
      overflow: 'hidden',
    });
		$('.showMoreDot-3').css("display","block");
    //続きを読むボタンクリックで全文表示
    // $more.click(function () {
    //   $(this).hide();
    //   $text.css({
    //     'height': textHeight,
    //     'overflow': 'visible',
    //   });
    //   return false;//aタグ無効化
    // });
  } else {
    // 指定した行数以下のテキストなら続きを読むは表示しない
    // $more.hide();
  }
});

$(function () {
  var $text = $('.textOverflow-4');//対象のテキスト
  var $more = $('.more');//続きを読むボタン
  var lineNum = 2;//表示する行数
  var textHeight = $text.height();//テキスト全文の高さ
  var lineHeight = parseFloat($text.css('line-height'));//line-height
  var textNewHeight = lineHeight * lineNum;//指定した行数までのテキストの高さ

  // テキストが表示制限の行数を超えたら発動
  if (textHeight > textNewHeight) {
    $text.css({
      height: textNewHeight,
      overflow: 'hidden',
    });
		$('.showMoreDot-4').css("display","block");
    //続きを読むボタンクリックで全文表示
    // $more.click(function () {
    //   $(this).hide();
    //   $text.css({
    //     'height': textHeight,
    //     'overflow': 'visible',
    //   });
    //   return false;//aタグ無効化
    // });
  } else {
    // 指定した行数以下のテキストなら続きを読むは表示しない
    // $more.hide();
  }
});

$(function () {
  var $text = $('.textOverflow-5');//対象のテキスト
  var $more = $('.more');//続きを読むボタン
  var lineNum = 2;//表示する行数
  var textHeight = $text.height();//テキスト全文の高さ
  var lineHeight = parseFloat($text.css('line-height'));//line-height
  var textNewHeight = lineHeight * lineNum;//指定した行数までのテキストの高さ

  // テキストが表示制限の行数を超えたら発動
  if (textHeight > textNewHeight) {
    $text.css({
      height: textNewHeight,
      overflow: 'hidden',
    });
		$('.showMoreDot-5').css("display","block");
    //続きを読むボタンクリックで全文表示
    // $more.click(function () {
    //   $(this).hide();
    //   $text.css({
    //     'height': textHeight,
    //     'overflow': 'visible',
    //   });
    //   return false;//aタグ無効化
    // });
  } else {
    // 指定した行数以下のテキストなら続きを読むは表示しない
    // $more.hide();
  }
});
